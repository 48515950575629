const ValidLanguages: string[] = [
  "aa",
  "ab",
  "ae",
  "af",
  "ak",
  "am",
  "an",
  "ar",
  "as",
  "av",
  "ay",
  "az",
  "ba",
  "be",
  "bg",
  "bh",
  "bi",
  "bm",
  "bn",
  "bo",
  "br",
  "bs",
  "ca",
  "ce",
  "ch",
  "co",
  "cr",
  "cs",
  "cu",
  "cv",
  "cy",
  "da",
  "de",
  "dv",
  "dz",
  "ee",
  "el",
  "en",
  "eo",
  "es",
  "et",
  "eu",
  "fa",
  "ff",
  "fi",
  "fj",
  "fo",
  "fr",
  "fy",
  "ga",
  "gd",
  "gl",
  "gn",
  "gu",
  "gv",
  "ha",
  "he",
  "hi",
  "ho",
  "hr",
  "ht",
  "hu",
  "hy",
  "hz",
  "ia",
  "id",
  "ie",
  "ig",
  "ii",
  "ik",
  "io",
  "is",
  "it",
  "iu",
  "ja",
  "jv",
  "ka",
  "kg",
  "ki",
  "kj",
  "kk",
  "kl",
  "km",
  "kn",
  "ko",
  "kr",
  "ks",
  "ku",
  "kv",
  "kw",
  "ky",
  "la",
  "lb",
  "lg",
  "li",
  "ln",
  "lo",
  "lt",
  "lu",
  "lv",
  "mg",
  "mh",
  "mi",
  "mk",
  "ml",
  "mn",
  "mo",
  "mr",
  "ms",
  "mt",
  "my",
  "na",
  "nb",
  "nd",
  "ne",
  "ng",
  "nl",
  "nn",
  "no",
  "nr",
  "nv",
  "ny",
  "oc",
  "oj",
  "om",
  "or",
  "os",
  "pa",
  "pi",
  "pl",
  "ps",
  "pt",
  "qu",
  "rm",
  "rn",
  "ro",
  "ru",
  "rw",
  "sa",
  "sc",
  "sd",
  "se",
  "sg",
  "sh",
  "si",
  "sk",
  "sl",
  "sm",
  "sn",
  "so",
  "sq",
  "sr",
  "ss",
  "st",
  "su",
  "sv",
  "sw",
  "ta",
  "te",
  "tg",
  "th",
  "ti",
  "tk",
  "tl",
  "tn",
  "to",
  "tr",
  "ts",
  "tt",
  "tw",
  "ty",
  "ug",
  "uk",
  "ur",
  "uz",
  "ve",
  "vi",
  "vo",
  "wa",
  "wo",
  "xh",
  "yi",
  "yo",
  "za",
  "zh",
  "zu",
  "aar",
  "abk",
  "ave",
  "afr",
  "aka",
  "amh",
  "arg",
  "ara",
  "asm",
  "ava",
  "aym",
  "aze",
  "bak",
  "bel",
  "bul",
  "bih",
  "bis",
  "bam",
  "ben",
  "tib",
  "bod",
  "bre",
  "bos",
  "cat",
  "che",
  "cha",
  "cos",
  "cre",
  "cze",
  "ces",
  "chu",
  "chv",
  "wel",
  "cym",
  "dan",
  "ger",
  "deu",
  "div",
  "dzo",
  "ewe",
  "gre",
  "ell",
  "eng",
  "epo",
  "spa",
  "est",
  "baq",
  "eus",
  "per",
  "fas",
  "ful",
  "fin",
  "fij",
  "fao",
  "fre",
  "fra",
  "fry",
  "gle",
  "gla",
  "glg",
  "grn",
  "guj",
  "glv",
  "hau",
  "heb",
  "hin",
  "hmo",
  "hrv",
  "hat",
  "hun",
  "arm",
  "hye",
  "her",
  "ina",
  "ind",
  "ile",
  "ibo",
  "iii",
  "ipk",
  "ido",
  "ice",
  "isl",
  "ita",
  "iku",
  "jpn",
  "jav",
  "geo",
  "kat",
  "kon",
  "kik",
  "kua",
  "kaz",
  "kal",
  "khm",
  "kan",
  "kor",
  "kau",
  "kas",
  "kur",
  "kom",
  "cor",
  "kir",
  "lat",
  "ltz",
  "lug",
  "lim",
  "lin",
  "lao",
  "lit",
  "lub",
  "lav",
  "mlg",
  "mah",
  "mao",
  "mri",
  "mac",
  "mkd",
  "mal",
  "mon",
  "mol",
  "mar",
  "may",
  "msa",
  "mlt",
  "bur",
  "mya",
  "nau",
  "nob",
  "nde",
  "nep",
  "ndo",
  "dut",
  "nld",
  "nno",
  "nor",
  "nbl",
  "nav",
  "nya",
  "oci",
  "oji",
  "orm",
  "ori",
  "oss",
  "pan",
  "pli",
  "pol",
  "pus",
  "por",
  "que",
  "roh",
  "run",
  "rum",
  "ron",
  "rus",
  "kin",
  "san",
  "srd",
  "snd",
  "sme",
  "sag",
  "slo",
  "sin",
  "slk",
  "slv",
  "smo",
  "sna",
  "som",
  "alb",
  "sqi",
  "srp",
  "ssw",
  "sot",
  "sun",
  "swe",
  "swa",
  "tam",
  "tel",
  "tgk",
  "tha",
  "tir",
  "tuk",
  "tgl",
  "tsn",
  "ton",
  "tur",
  "tso",
  "tat",
  "twi",
  "tah",
  "uig",
  "ukr",
  "urd",
  "uzb",
  "ven",
  "vie",
  "vol",
  "wln",
  "wol",
  "xho",
  "yid",
  "yor",
  "zha",
  "chi",
  "zho",
  "zul",
];
export default ValidLanguages;
